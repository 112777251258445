import lang_id from './lang/id.json'
import lang_en from './lang//en.json'
import { IPropertyTypeNumber } from './Types';
import { _getCookie, _setCookie } from './Cookie';
import { toast } from 'react-toastify';

declare global {
    interface Window {
        dataLayer: any;
    }
}

export function getListingType(lang_code: string, id: number | string) {
    const id_to_check = Number(id);
    let text = "";

    if (lang_code === 'en') {
        switch (id_to_check) {
            case 2:
                text = "house";
                break;
            case 3:
                text = `land`;
                break;
            case 4:
                text = `shophouse`;
                break;
            case 5:
                text = `office`;
                break;
            case 6:
                text = `warehouse`;
                break;
            case 7:
                text = `business-space`;
                break;
            default:
                text = `listing`;
                break;
        }
    } else {
        switch (id_to_check) {
            case 2:
                text = "rumah";
                break;
            case 3:
                text = `tanah`;
                break;
            case 4:
                text = `ruko`;
                break;
            case 5:
                text = `kantor`;
                break;
            case 6:
                text = `gudang`;
                break;
            case 7:
                text = `ruang-usaha`;
                break;
            default:
                text = `listing`;
                break;
        }
    }
    return text;
}

export function getLangCode(pathName: string) {
    const match = pathName.match(/^\/(\w{2})\//);
    if (match && match[1]) {
        return match[1];
    }
    return '';
}

export function isDateValid(dateStr: string) {
    return !isNaN(new Date(dateStr) as any)
}

export function getLanguage(lang_code: string) {
    if (lang_code && lang_code !== '') {
        const lang = lang_code === 'en' ? lang_en : lang_id
        return lang
    }
    return lang_id
}

export function parseLanguage(lang: string, key: string, replace: string) {
    return lang.replace(key, replace)
}

export const checkStillIndonesianSlug = (slug: string) => slug.includes('jakarta-pusat') || slug.includes('jakarta-barat') || slug.includes('jakarta-selatan') || slug.includes('jakarta-utara') || slug.includes('jakarta-timur') || slug.includes('tangerang-selatan')
export const checkStillEnglishSlug = (slug: string) => slug.includes('central-jakarta') || slug.includes('west-jakarta') || slug.includes('south-jakarta') || slug.includes('north-jakarta') || slug.includes('east-jakarta') || slug.includes('south-tangerang')

export const parseEnUrl = (url: string) => {
    if (url.includes('central-jakarta')) return url.replace('central-jakarta', 'jakarta-pusat')
    if (url.includes('west-jakarta')) return url.replace('west-jakarta', 'jakarta-barat')
    if (url.includes('south-jakarta')) return url.replace('south-jakarta', 'jakarta-selatan')
    if (url.includes('north-jakarta')) return url.replace('north-jakarta', 'jakarta-utara')
    if (url.includes('east-jakarta')) return 'jakarta-timur'
    if (url.includes('south-tangerang')) return url.replace('south-tangerang', 'tangerang-selatan')

    return url
}

export const parseIdUrl = (url: string) => {
    if (url.includes('jakarta-pusat')) return url.replace('jakarta-pusat', 'central-jakarta')
    if (url.includes('jakarta-barat')) return url.replace('jakarta-barat', 'west-jakarta')
    if (url.includes('jakarta-selatan')) return url.replace('jakarta-selatan', 'south-jakarta')
    if (url.includes('jakarta-utara')) return url.replace('jakarta-utara', 'north-jakarta')
    if (url.includes('jakarta-timur')) return url.replace('jakarta-timur', 'east-jakarta')
    if (url.includes('tangerang-selatan')) return url.replace('tangerang-selatan', 'south-tangerang')

    return url
}

export function generateUrl(lang_code: string, url: string) {
    if (lang_code === 'en') {
        return `${process.env.NEXT_PUBLIC_MAINSITE_URL}/en${parseIdUrl(url)}`
    } else {
        return `${process.env.NEXT_PUBLIC_MAINSITE_URL}${parseEnUrl(url)}`
    }
}

export function isAreaJakarta(area: string) {
    return area.toLowerCase().includes('jakarta')
}

export function getLangJakarta(area: string) {
    let arr = area.split(' ')
    arr = arr.map(str => {
        return str.toLowerCase()
    })
    return arr.join('-')
}

export function getAreaLang(lang: any, area: string) {
    if (area.toLowerCase().includes('jakarta')) {
        let arr = area.split(' ')
        arr = arr.map(str => {
            return str.toLowerCase()
        })
        const key = arr.join('-')
        return lang[key]
    } else {
        return area
    }
}

export function slugToText(slug: string) {
    let slugArr = slug.split('-')
    slugArr = slugArr.map(str => str[0].toUpperCase() + str.slice(1))
    return slugArr.length > 1 ? slugArr.join(' ') : slugArr[0]
}

export function convertIndonesiaPhoneNumber(number: string) {
    const first_two_character = number.substring(0, 2);

    if (first_two_character === "08") {
        number = number.replace(/^08/g, "628");
    }

    return number.replace(/[^\d]/, '');
}

export function convertNormalPhoneNumber(number: string) {
    const first_three_character = number.substring(0, 3);

    if (first_three_character === "628") {
        number = number.replace(/^628/g, "08");
    }

    return number.replace(/[^\d]/, '');
}

export function convertNumberFormat(number_to_convert: string) {
    let converted_number = number_to_convert.toString();
    converted_number = converted_number.replace('.00', '');
    let number = converted_number.replace(/[^\d]/g, '');
    let num = number.replace(/,/gi, "");
    let num2 = num.split(/(?=(?:\d{3})+$)/).join(".");

    if (num2 === "0") {
        return "";
    }

    return num2;
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export const validatePhoneNumber = (phoneNumber: string) => phoneNumber.toString().length >= 4 && phoneNumber.toString().length <= 25

export const validatePhoneNumberWithoutCountryCode = (phoneNumber: string) => phoneNumber.toString().length >= 10 && phoneNumber.toString().length <= 25

export function isMobile() {
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
        return true;
    } else {
        return false;
    }
}

export function isLg() {
    return window.matchMedia("only screen and (min-width: 1024px)").matches
}

export function getCurrentBreakpoint() {
    if (window.matchMedia("only screen and (min-width: 1600px)").matches) {
        return '2xl'
    }
    if (window.matchMedia("only screen and (min-width: 1170px)").matches) {
        return 'xl'
    }
    if (window.matchMedia("only screen and (min-width: 1024px)").matches) {
        return 'lg'
    }
    if (window.matchMedia("only screen and (min-width: 768px)").matches) {
        return 'md'
    }
    if (window.matchMedia("only screen and (min-width: 640px)").matches) {
        return 'sm'
    }
    return 'xs'
}

export function removeDecimal(number: string | undefined) {
    if (typeof number === 'undefined') {
        return "";
    } else {
        const exploded_number = number.split(".");
        return exploded_number[0];
    }
}

export function convertNumber(number: string, prefix?: string, with_decimal?: boolean) {
    let numberString;
    let decimal;

    //With Decimal
    if (with_decimal) {
        number = Number(number).toFixed(2);

        const exploded_number = number.split(".");
        numberString = parseInt(exploded_number[0].replace(/[^,\d]/g, '')).toString();
        decimal = typeof exploded_number[1] !== 'undefined' ? exploded_number[1] : "";
    } else {
        numberString = parseInt(number.replace(/[^,\d]/g, '')).toString();
    }

    const split = numberString.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah = rupiah + separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    let output = prefix ? prefix : ''

    if (with_decimal) {
        return rupiah ? `${output}${rupiah},${decimal}` : '';
    } else {
        return rupiah ? output + rupiah : ''
    }
}


export function convertPercentangeWithDecimal(number: string, max_number?: number) {
    if (typeof max_number === 'undefined') {
        max_number = 100;
    }

    if (number === "") {
        return "";
    }

    if ((number.match(/[.]/g) || []).length === 1) {
        if (number[number.length - 1] === '.') {
            const max_data = number.replace('.', '');

            if (parseInt(max_data) >= max_number) {
                return max_number;
            }
            return number;
        } else {
            const number_splitted = number.split('.');

            if (number_splitted[1].length === 1) {
                return parseFloat(number).toFixed(1);
            } else if (number_splitted[1].length === 2) {
                return parseFloat(number).toFixed(2);
            } else {
                return false;
            }
        }
    } else if ((number.match(/[.]/g) || []).length > 1) {
        return false;
    }

    if (isNaN(parseFloat(number))) {
        return false;
    } else {
        let new_value = parseFloat(number);
        new_value = new_value > max_number ? max_number : new_value;
        return new_value;
    }
}

export function inputNumber(number: string) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    return numberString ? numberString : ''
}

export function inputNumberWithoutComma(number: string) {
    const numberString = number.replace(/[^\d]/g, '').toString()
    return numberString ? numberString : ''
}

export function inputText(text: string) {
    const textString = text.replace(/[^a-zA-Z0-9 ]/g, '').toString()
    return textString ? textString : ''
}

export function getMonth(month: number, shortHand?: boolean) {
    let months: string[] = []
    if (shortHand) {
        months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
    } else {
        months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    return months[month]
}

export function censorWords(words: string) {
    let arrayOfWords = words.split(' ')
    arrayOfWords = arrayOfWords.map((word) => {
        return word[0] + '*'.repeat(word.length)
    })
    return arrayOfWords.join(' ')
}

export function currencyToNumber(currency: string) {
    return currency.replace(/[^0-9]/g, '')
}

export function numberToCurrency(number: number | string, prefix?: string) {
    const formatter = new Intl.NumberFormat('en-US');
    const newNumber: unknown = String(number).replace(/[.]\d{2}$/, '').replace(/[^0-9]/g, '');
    return `${prefix ? prefix : ''} ${formatter.format(newNumber as number)}`.trim();
}

export function parseQueryString(url: string) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj: any = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)![1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
}

export function getNumberText(num: string | number, lang: any) {
    if (typeof num === 'string') {
        num = parseInt(num)
    }
    if (num >= 1000000000) {
        let value = (num / 1000000000).toFixed(1).toString()
        const value_array = value.split('.')
        if (value_array[1] && value_array[1] === '0') {
            value = value_array[0]
        }
        return value + ' ' + lang.billion
    } else if (num >= 1000000) {
        let value = (num / 1000000).toFixed(1).toString()
        const value_array = value.split('.')
        if (value_array[1] && value_array[1] === '0') {
            value = value_array[0]
        }
        return value + ' ' + lang.million
    } else {
        return convertNumber(num.toString())
    }
}

export function sendGA(category: string, label: string, action = 'click') {
    if (typeof window !== 'undefined') {
        import('react-ga')
            .then(module => module.default)
            .then(async (ReactGA) => {
                const ga = ReactGA.ga();

                const isFunction = (await (import('lodash/isFunction'))).default

                if (isFunction(ga)) {
                    ga('create', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, 'auto');
                    if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
                        ga('send', 'event', category, action, label);
                    }
                }
            })
            .catch(err => {
                console.error(err)
            })
    }
}

export const renderDate = (
    date: Date | string,
    withDate: boolean, // render date
    withTime: boolean, // render time
    onlyMonthYear?: boolean, // is only render month and year for the date
    shortHand?: boolean, // is month using shorthand
    numberOnly?: boolean, // only render using number (not using month name)
    dateSep?: string, // separator for date format
    dateTimeSep?: string, // separator between date and time
    is24Hour?: boolean, // is time using 24 hour format
) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    let outputText = ''
    if (withDate) {
        if (numberOnly) {
            if (!onlyMonthYear) outputText += ((date.getDate() < 10) ? '0' + date.getDate().toString() : date.getDate()) + (dateSep || ' ')
            outputText += ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1))
                + (dateSep || '')
                + date.getFullYear()
        } else {
            if (!onlyMonthYear) outputText += ((date.getDate() < 10) ? '0' + date.getDate().toString() : date.getDate()) + (dateSep || ' ')
            if (numberOnly) {
                outputText += ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1)) + (dateSep || ' ')
            } else {
                outputText += getMonth(date.getMonth(), shortHand) + (dateSep || ' ')
            }
            outputText += date.getFullYear()
        }
    }
    if (withTime) {
        if (outputText !== '') {
            outputText += (dateTimeSep || ' at ')
        }
        if (is24Hour) {
            outputText += ((date.getHours() < 10) ? '0' + date.getHours().toString() : date.getHours())
                + ':'
                + ((date.getMinutes() < 10) ? '0' + date.getMinutes().toString() : date.getMinutes())
                + ':'
                + ((date.getSeconds() < 10) ? '0' + date.getSeconds().toString() : date.getSeconds())
        } else {
            outputText += ((date.getHours() > 12) ? date.getHours() - 12 : date.getHours())
                + ':'
                + ((date.getMinutes() < 10) ? '0' + date.getMinutes().toString() : date.getMinutes())
                + ' '
                + (date.getHours() > 12 ? 'PM' : 'AM')
        }
    }
    return outputText
}

export function generateSlug(text: string, sepSource: string, sepOutput: string) {
    let arrText = text.split(sepSource)
    arrText = arrText.map(txt => txt.toLowerCase())
    return arrText.join(sepOutput)
}

export const copyToClipboard = (content: string) => {
    // Create a dummy input to copy the string array inside it
    let dummy = document.createElement("input") as HTMLInputElement

    // Add it to the document
    document.body.appendChild(dummy);

    // Output the array into it
    dummy.value = content;

    // Select it
    dummy.select();

    // Copy its contents
    document.execCommand("copy");

    // Remove it as its not needed anymore
    document.body.removeChild(dummy);

}



export const copyLink = (url: string, langcode: string) => {
    copyToClipboard(url)
    toast.success(langcode === "en" ? lang_en.copy_link : lang_id.copy_link, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success",
        progress: undefined,
        className: "bg-green-500 text-black-48",
    })
}

export function parseSlug(text: string, sepSource: string = '-') {
    let arrText = text.split(sepSource)
    arrText = arrText.filter(item => item).map(txt => txt[0].toUpperCase() + txt.slice(1))
    const output: string = arrText.join(' ')
    return output
}

export function resizeAndResetOrientationImage(file: File) {
    return new Promise<File>((resolve, reject) => {
        getOrientation(file, (orientation: any) => {
            resetOrientation(URL.createObjectURL(file), orientation, (newImg: any) => {
                let img = new Image()
                let reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target) {
                        img.src = e.target.result as string;
                        img.onload = () => {
                            let canvas = document.createElement("canvas");
                            let ctx = canvas.getContext("2d");
                            if (ctx) {
                                ctx.drawImage(img, 0, 0);
                                let MAX_WIDTH = 800;
                                let MAX_HEIGHT = 800;
                                let width = img.width;
                                let height = img.height;
                                if (width > height) {
                                    if (width > MAX_WIDTH) {
                                        height *= MAX_WIDTH / width;
                                        width = MAX_WIDTH;
                                    }
                                } else {
                                    if (height > MAX_HEIGHT) {
                                        width *= MAX_HEIGHT / height;
                                        height = MAX_HEIGHT;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                ctx.drawImage(img, 0, 0, width, height);
                                let dataurl = canvas.toDataURL("image/png");
                                let [mimeType] = dataurl.replace('data:', '').split(';');
                                resolve(urlToFile(dataurl, file.name, mimeType));
                            }
                        };
                        img.onerror = function () {
                            alert('Invalid file type: ' + file.type);
                        };
                    }
                }
                reader.readAsDataURL(newImg);
            })
        })
    })

}
export function getOrientation(file: any, callback: any) {
    let reader = new FileReader();

    reader.onload = (event) => {
        var target = event.target;
        if (target) {
            var view = new DataView(target.result as any);

            if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);

            var length = view.byteLength,
                offset = 2;

            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return callback(-1);
                    }
                    var little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;

                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                }
                else if ((marker & 0xFF00) !== 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        }
    }

    reader.readAsArrayBuffer(file);
}

export function resetOrientation(srcBase64: any, srcOrientation: number, callback: any) {
    var img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        if (ctx) {
            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            // transform context before drawing image
            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            // draw image
            ctx.drawImage(img, 0, 0);

            // export blob
            canvas.toBlob((blob: any) => {
                callback(blob);
            })
        }
    };

    img.src = srcBase64;
}

export function urlToFile(url: string, filename: string, mimeType: string) {
    return (
        fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

/**
 * Shallowly compare two objects with identical properties
 * @param a first object to be compared
 * @param b object to be compared
 * @param spesificKeys spesific keys that want to be compared
 */
export function areEqualShallow(a: any, b: any, spesificKeys?: string[]): boolean {
    let valid = true
    if (!!spesificKeys || (!!a && typeof a === 'object')) {
        let keys = spesificKeys || Object.keys(a)

        keys.forEach(key => {
            if (typeof a[key] === 'object') {
                if (a[key] instanceof Array) {
                    a[key].forEach((val: any, idx: number) => {
                        if (val !== b[key][idx]) valid = false
                    })
                } else {
                    valid = areEqualShallow(a[key], b[key])
                }
            } else {
                if (a[key] !== b[key]) {
                    valid = false
                }
            }
        })
    }

    return valid
}

/**
 * Shallowly compare two objects with identical properties
 * @param a first object to be compared
 * @param b object to be compared
 * @param spesificKeys spesific keys that want to be compared
 */
export function countDifferenceShallow(a: any, b: any, count: number, spesificKeys?: string[]): number {
    if (!!spesificKeys || (!!a && typeof a === 'object')) {
        let keys = spesificKeys || Object.keys(a)

        keys.forEach(key => {
            if (typeof a[key] === 'object') {
                if (a[key] instanceof Array) {
                    a[key].forEach((val: any, idx: number) => {
                        if (val !== b[key][idx]) count = count + 1
                    })
                } else {
                    count = count + countDifferenceShallow(a[key], b[key], count)
                }
            } else {
                if (a[key] !== b[key]) count = count + 1
            }
        })
    }

    return count
}

/**
 * Parse property type to their respected string
 * @param lang language dictionay object
 * @param propertyType property type in string
 * @param langCode specific lang code, if you want to bypass the default language dictionary
 */
export const getListingTypeLanguage = (lang: any, propertyType: IPropertyTypeNumber, langCode?: 'en' | 'id') => {
    const localLang = langCode ? getLanguage(langCode) : lang
    switch (propertyType) {
        case '1':
            return localLang.apartment
        case '2':
            return localLang.house
        case '3':
            return localLang.land
        case '4':
            return localLang.shophouse
        case '5':
            return localLang.office
        case '6':
            return localLang.warehouse
        case '7':
            return localLang.business_space
        default:
            return ''
    }
}

/**
 * Parse cookie string to JS object
 * @param cookie_string cookie string from req.headers.cookie
 */
export const parseCookie = (cookie_string: string): any => {
    try {
        return cookie_string
            .split(';')
            .map(v => v.split('='))
            .reduce((acc: any, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {})
    } catch {
        return {}
    }
}



export const parseFloor = (floor: string) => {
    switch (floor) {
        case 'low':
            return 'lantai-rendah'
        case 'middle':
            return 'lantai-tengah'
        case 'high':
            return 'lantai-atas'
        default:
            return ''
    }
}

export const unauthorizedErrorHandler = (type: 'refresh' | 'redirect', redirectTo?: string) => {
    if (process.browser) {
        switch (type) {
            case 'refresh':
                window.location.reload()
                break
            case 'redirect':
                if (redirectTo) window.location.href = redirectTo
                break
        }
    }
}

export const useStorage = (callback: () => void) => {
    try {
        callback()
    } catch {
        // console.error('Cookies disabled')
    }
}

export const fetchEncode = (url: string, init?: RequestInit | undefined): Promise<Response> => {
    return fetch(encodeURI(url), init)
}

export const replaceUrlParam = (url: string, data: any, action: 'push' | 'replace') => {
    let newUrl = url.replace('fh=1', '').replace('&&', '&').replace('?&', '&');
    for (let [paramName, paramValue] of Object.entries(data)) {
        let pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)')
        if (paramValue == null || paramValue == '') {
            paramValue = ''
            newUrl = newUrl.replace(pattern, '').replace(/[&#]$/, '')
        } else if (newUrl.search(pattern) >= 0) {
            newUrl = newUrl.replace(pattern, '$1' + paramValue + '$2')
        } else {
            newUrl = newUrl.replace(/[?#]$/, '')
            newUrl = newUrl + (newUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
        }
    }

    switch (action) {
        case 'push':
            window.history.pushState(null, '', newUrl);
            break
        case 'replace':
            window.history.replaceState(null, '', newUrl);
            break
    }
}

export const FBDataLayerViewContentApartment = (title: string, content_id: string, property_area: string, property_district: string,
    property_for: string, value: string,
    content_name: string, city: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `ApartemenPage`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'content_ids': `${content_id}`,
            'property_area': `${property_area}`,
            'property_district': `${property_district}`,
            'property_for': `${property_for}`,
            'value': `${value}`,
            'content_name': `${content_name}`,
            'content_type': `apartment_page`,
            'currency': `IDR`,
            'city': `${city}`,
            'country': `Indonesia`
        });
    }
}

export const FBDataLayerViewContentUnit = (title: string, content_id: string, property_area: string, property_district: string,
    property_name: string, property_type: string, property_for: string, unit_type: string, value: string,
    content_name: string, city: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `PropertyPage`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'content_ids': `${content_id}`,
            'property_area': `${property_area}`,
            'property_district': `${property_district}`,
            'property_name': `${property_name}`,
            'property_type': `${property_type}`,
            'property_for': `${property_for}`,
            'unit_type': `${unit_type}`,
            'value': `${value}`,
            'content_name': `${content_name}`,
            'content_type': `property_page`,
            'currency': `IDR`,
            'city': `${city}`,
            'country': `Indonesia`
        });
    }
}

export const FBDataLayerViewContentProperty = (title: string, content_id: string, property_area: string, property_district: string,
    property_type: string, property_for: string, unit_type: string, value: string,
    content_name: string, city: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `PropertyPage`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'content_ids': `${content_id}`,
            'property_area': `${property_area}`,
            'property_district': `${property_district}`,
            'property_type': `${property_type}`,
            'property_for': `${property_for}`,
            'unit_type': `${unit_type}`,
            'value': `${value}`,
            'content_name': `${content_name}`,
            'content_type': `property_page`,
            'currency': `IDR`,
            'city': `${city}`,
            'country': `Indonesia`
        });
    }
}

export const properScrollTo = (top: number, behavior?: ScrollBehavior) => {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

    if (isSmoothScrollSupported) {
        window.scrollTo({
            top,
            behavior: behavior || "smooth" as ScrollBehavior,
        })
    } else {
        document.documentElement.scrollTop = top
    }
}

export const getIsIOS = () => [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    // Old detection
    || navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1

export const prepareFullUrlForLanguage = (url: string, isEnglish: boolean) => {
    const siteUrl = process.env.NEXT_PUBLIC_MAINSITE_URL || ''
    const cleanedUrl = url.replace(siteUrl, '')

    return `${siteUrl}${isEnglish ? '/en' : ''}${cleanedUrl}`
}

export const getOrdinalSuffixFrom = (i: number) => {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const roundUp = (
    /**
     * Value to be rounded up
     */
    value: number,
    /**
     * Number after the comma (precision)
     */
    precision: number
) => {
    var multiplier = Math.pow(10, precision || 0)
    return Math.ceil(value * multiplier) / multiplier
}

export const setBrowserId = () => {
    const { v4: uuidv4 } = require('uuid');
    const browserId = _getCookie('browser_id')

    if (!browserId) {
        _setCookie('browser_id', uuidv4())
    }
}

export const parseHTMLContent = (text: string) => (
    text
        .replace(
            /<h(\d)>/g,
            "<h$1 class='text-2xl font-semibold mb-3 leading-tight'>"
        )
        .replace(/<p>/g, "<p class='text-sm mb-2'>")
        .replace(/<ul>/g, "<ul class='pl-4'>")
        .replace(/<ol>/g, "<ol class='pl-4'>")
        .replace(
            /<a ([^>]+)>/g,
            "<a $1 class='text-main hover:text-main-dark font-semibold mb-3 leading-tight'>"
        )
)

export const translateDirectionLocation = (text: string, langCode: string) => {
    const config = [
        { regex: /timur/gi, translate: 'East ' },
        { regex: /barat/gi, translate: 'West ' },
        { regex: /selatan/gi, translate: 'South ' },
        { regex: /utara/gi, translate: 'North ' },
        { regex: /pusat/gi, translate: 'Central ' },
    ]
    if (langCode === 'en') {
        let newText = ""
        config.forEach((config) => {
            if (config.regex.test(text) && !newText) {
                newText = text.replace(config.regex, "").trim().replace(/^/, config.translate)
            }
        })

        return newText || text
    }

    return text
}

export const isScrollPastElement = (element: HTMLDivElement | null, offset: number = 0) => {
    if (!element) return false

    // Get current scroll
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    // Get element position
    const elementYPosition = element.getBoundingClientRect().top + (window.scrollY || window.pageYOffset)

    return (currentScroll + offset) > elementYPosition
}

export const getClosestRating = (rating: number) => {
    const decimals = Number((rating % 1).toFixed(2))
    if (decimals < 0.24) {
        return 0
    } else if (decimals < 0.74) {
        return 0.5
    } else {
        return 1
    }
}

export const formatArticleContent = (text: string, withBreakLine?: boolean) => {
    let replacedText = text
        .replace(/<h1[^>]*>/g, '<h1 class="text-2xl font-semibold mb-3 leading-tight">')
        .replace(/<h2[^>]*>/g, '<h2 class="text-xl font-semibold mb-3 leading-tight">')
        .replace(/<h3[^>]*>/g, '<h3 class="text-lg font-semibold mb-3 leading-tight">')
        .replace(/<h4[^>]*>/g, '<h4 class="text-base font-semibold mb-3 leading-tight">')
        .replace(/<h5[^>]*>/g, '<h5 class="text-md font-semibold mb-3 leading-tight">')
        .replace(/<h6[^>]*>/g, '<h6 class="text-xs font-semibold mb-3 leading-tight">')
        .replace(/<p>/g, "<p class='text-sm mb-2'>")
        .replace(/<ul>/g, "<ul class='pl-4'>")
        .replace(/<ol>/g, "<ol class='pl-4'>")
        .replace(
            /<a ([^>]+)>/g,
            "<a $1 class='text-main hover:text-main-dark font-semibold mb-3 leading-tight'>"
        );
    
    if (withBreakLine) {
        replacedText = replacedText.replace(/\n/g, '<br />');
    }

    return replacedText;
};

