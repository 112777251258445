import Cookie from 'js-cookie'

export const _storeLoginInfo = (token: string, expires: number) => {
    // Expires is using 1 day interval, so it need to be divided by 60 * 60 * 24
    Cookie.set('_token', token, { expires: expires/(60 * 60 * 24) })
    return true
}

export const _getUser = () => {
    return Cookie.get('_user')
}

export const _getToken = () => {
    return Cookie.get('_token')
}

export const _removeToken = () => {
    return Cookie.remove('_token')
}

export const _setCookie = (name: string, value: string) => {
    return Cookie.set(name, value)
}

export const _getCookie = (name: string) => {
    return Cookie.get(name)
}

export const _removeCookie = (name: string) => {
    return Cookie.remove(name)
}